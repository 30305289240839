export default {
  path: "/department",
  name: "department",
  props: true,
  component: () => import("@/views/LayoutView.vue"),
  children: [
    {
      path: "",
      redirect: "place/list",
    },
    {
      path: ":dir(place)/view/:id",
      name: "departmentPlaceView",
      component: () => import("@/views/department/place/PlaceView.vue"),
    },
    {
      path: ":dir(place)/:subdir(draft)/(list)*",
      component: () => import("@/components/default/list/DefaultList.vue"),
      props: {
        getDataUrl: "place?status_id=1",
      },
    },
    {
      path: ":dir(place)/:subdir(published)/(list)*",
      component: () => import("@/components/default/list/DefaultList.vue"),
      props: {
        getDataUrl: "place?status_id=2",
      },
    },
    {
      path: ":dir(place)/:subdir(archive)/(list)*",
      component: () => import("@/components/default/list/DefaultList.vue"),
      props: {
        getDataUrl: "place?status_id=3",
      },
    },
    {
      path: ":dir(place)/:subdir(revoked)/(list)*",
      component: () => import("@/components/default/list/DefaultList.vue"),
      props: {
        getDataUrl: "place?status_id=4",
      },
    },

    {
      path: ":dir(organization)/:subdir(new)/(list)*",
      component: () => import("@/components/default/list/DefaultList.vue"),
      props: {
        getDataUrl: "organization?status_id=2",
      },
    },
    {
      path: ":dir(organization)/:subdir(allowed)/(list)*",
      component: () => import("@/components/default/list/DefaultList.vue"),
      props: {
        getDataUrl: "organization?status_id=3",
      },
    },
    {
      path: ":dir(organization)/:subdir(not-allowed)/(list)*",
      component: () => import("@/components/default/list/DefaultList.vue"),
      props: {
        getDataUrl: "organization?status_id=4",
      },
    },
    {
      path: ":dir(organization)/:subdir(out-of-date)/(list)*",
      component: () => import("@/components/default/list/DefaultList.vue"),
      props: {
        getDataUrl: "organization?status_id=5",
      },
    },
    {
      path: ":dir(organization)/:subdir(blocked)/(list)*",
      component: () => import("@/components/default/list/DefaultList.vue"),
      props: {
        getDataUrl: "organization?status_id=6",
      },
    },

    {
      path: ":parentDir(place)/:placeId/:dir(apply)/view/:id",
      name: "placeView",
      component: () => import("@/views/department/place/apply/ApplyView.vue"),
    },
    {
      path: ":parentDir(monitoring)/:placeId/:dir(course)/view/:id",
      name: "coursePlaceView",
      component: () => import("@/views/department/course/apply/ApplyView.vue"),
    },
    {
      path: ":parentDir(monitoring)/:placeId/:dir(staff)/view/:id",
      name: "coursePlaceView",
      component: () => import("@/views/department/staff/apply/ApplyView.vue"),
    },

    {
      path: ":dir/(list)*",
      component: () => import("@/components/default/list/DefaultList.vue"),
      props: true,
    },
    {
      path: ":dir(commision)/view/:id",
      component: () => import("@/components/default/view/DefaultView.vue"),
      props: true,
    },
    {
      path: ":dir(organization)/view/:id",
      component: () => import("@/components/default/view/DefaultView.vue"),
      props: true,
    },
    // {
    //   path: ":dir(monitoring)/view/:id",
    //   component: () =>
    //     import("@/components/default/viewMonitoring/DefaultViewMonitoring.vue"),
    //   props: true,
    // },
    {
      path: ":dir(monitoring)/view/:id",
      name: "departmentCoursePlaceView",
      component: () => import(`@/views/department/course/PlaceView.vue`),
    },
    {
      path: ":dir(monitoring)/view/:id",
      name: "departmentCoursePlaceView",
      component: () => import(`@/views/department/staff/PlaceView.vue`),
    },
    {
      path: ":dir(act)/view/:id",
      name: "departmentCoursePlaceView",
      component: () => import(`@/views/department/staff/PlaceView.vue`),
    },
    {
      path: ":dir(tickets)/view/:id",
      component: () => import("@/views/superadmin/ticket/TicketView.vue"),
    },
  ],
};
