export default {
  path: "/staff",
  name: "staff",
  component: () => import("@/views/staff/LayoutView.vue"),
  children: [
    {
      path: "",
      redirect: "profile",
    },
    {
      path: "profile",
      props: {
        getDataUrl: "/profile",
      },
      component: () => import("@/components/default/view/DefaultView.vue"),
    },
    {
      path: ":dir(journal)/view/:id",
      component: () => import("@/views/staff/journal/JournalView.vue"),
    },

    {
      path: ":dir(monitoring)",
      props: {
        getDataUrl: "/monitoring",
      },
      // name: "monitoringView",
      component: () => import("@/components/default/view/DefaultView.vue")
    },

    {
      path: ":dir(gos-journal)/view/:id",
      component: () => import("@/views/staff/journal/JournalView.vue"),
    },
    {
      path: ":dir(tickets)/view/:id",
      component: () => import("@/views/superadmin/ticket/TicketView.vue"),
    },
    {
      path: ":dir/(list)*",
      component: () => import("@/components/default/list/DefaultList.vue"),
    },
    {
      path: ":dir/view/:id",
      component: () => import("@/components/default/view/DefaultView.vue"),
    },
  ],
};
