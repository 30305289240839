<template>
  <v-app>
    <v-app-bar app flat>
      <v-container class="py-0 fill-height">
        <v-btn
          :href="'https://zerek.edus.kz/' + selectedLanguage + index"
          v-for="(link, index) in links"
          :key="index"
          text
          class="header-menu-item white--text"
        >
          {{ link }}
        </v-btn>
        <v-btn
          :href="'https://gos.zerek.edus.kz/'"
          text
          class="header-menu-item white--text"
        >
          {{ $t("Государственные организации") }}
        </v-btn>

        <v-responsive class="shrink" style="margin-left: auto">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                depressed
                class="white--text transparent"
              >
                {{ languages[selectedLanguage] }}
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                link
                v-for="(item, index) in languages"
                :key="index"
                @click.stop="changeLanguage(index)"
              >
                <v-list-item-title>{{ item }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-responsive>
      </v-container>
    </v-app-bar>
    <v-container v-if="loginType == 'superadmin'">
      <div class="text-center">
        <br /><br />
        <h3 class="login-title my-16">{{ $t("Вход в кабинет") }}</h3>
      </div>

      <v-row justify="center">
        <v-col>
          <v-tabs v-model="activeTab" background-color="transparent" centered>
            <v-tab @click="changeLoginType('superadmin')">{{
              $t("superadmin")
            }}</v-tab>
          </v-tabs>
        </v-col>
      </v-row>

      <v-row class="align-center" justify="center">
        <v-col class="login-form-block" lg="9" md="9">
          <div class="form-wrap pa-10">
            <v-row justify="center">
              <v-col>
                <div class="login-btn-group">
                  <template
                    v-if="
                      loginType == 'superadmin' || loginType == 'superadmin'
                    "
                  >
                    <v-btn
                      @click="changeLoginType('superadmin')"
                      :class="{ active: loginType == 'superadmin' }"
                      >{{ $t("Login") }}
                    </v-btn>
                  </template>

                  
                </div>
              </v-col>
            </v-row>
            <v-form
              class="login-form"
              ref="form"
              @submit.prevent="handleSubmit"
            >
              <v-text-field
                color="indigo lighten-1"
                class="mb-4"
                prepend-icon="mdi-account"
                v-model="emailData"
                :label="$t('Email')"
                :type="showEmail ? 'text' : 'email'"
                :rules="[(v) => !!v || $t('Введите email')]"
              ></v-text-field>
              <v-text-field
                color="indigo lighten-1"
                :label="$t('Пароль')"
                prepend-icon="mdi-key"
                v-model="ecpPassword"
                :type="showPassword ? 'text' : 'password'"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[(v) => !!v || $t('Введите пароль')]"
                @click:append="showPassword = !showPassword"
              ></v-text-field>

              <v-flex class="d-flex justify-center">
                <v-btn
                  height="50px"
                  class="login-btn"
                  type="submit"
                  :loading="loading"
                >
                  {{ $t("Войти") }}
                </v-btn>
              </v-flex>
            </v-form>
          </div>
        </v-col>
      </v-row>
      <v-dialog v-model="otherAccountsDialog" max-width="500" persistent>
        <v-card>
          <v-card-title class="text-h5">
            {{ $t("Выберите организацию") }}
            <v-spacer></v-spacer>
            <v-btn icon small @click="closeAccountDialog()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text>
            <v-list three-line>
              <v-list-item
                v-for="(account, accountKey) in otherAccounts"
                :key="accountKey"
                @click="selectAccount(account)"
              >
                <v-list-item-content>
                  <v-list-item-title>{{
                    account.user.branch_name
                  }}</v-list-item-title>
                  <v-list-item-subtitle
                    ><b>{{ account.user.organization_name }}</b>
                    {{ account.user.full_address }}</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-dialog>

    </v-container>
    <v-container v-else>
      <div class="text-center">
        <br /><br />
        <h3 class="login-title my-16">{{ $t("Вход в кабинет") }}</h3>
      </div>

      <v-row justify="center">
        <v-col>
          <v-tabs v-model="activeTab" background-color="transparent" centered>
            <v-tab @click="changeLoginType('head-department')">{{
              $t("Управление Образования")
            }}</v-tab>
            <v-tab @click="changeLoginType('department')">{{
              $t("Отдел Образования")
            }}</v-tab>
            <v-tab @click="changeLoginType('organization')">{{
              $t("Поставщики")
            }}</v-tab>
            <v-tab @click="changeLoginType('parent')">{{
              $t("Родитель")
            }}</v-tab>

            <!-- <v-tab @click="changeLoginType('gos-organization')">{{
              $t("Гос организации")
            }}</v-tab> -->
          </v-tabs>
        </v-col>
      </v-row>
      <v-row class="align-center" justify="center">
        <v-col class="login-form-block" lg="9" md="9">
          <div class="form-wrap pa-10">
            <v-row justify="center">
              <v-col>
                <div class="login-btn-group">
                  <template v-if="loginType == 'head-department'">
                    <v-btn
                      @click="changeLoginType('head-department')"
                      :class="{ active: loginType == 'head-department' }"
                      >{{ $t("Администратор") }}
                    </v-btn>
                  </template>
                  <template
                    v-else-if="
                      loginType == 'department' || loginType == 'commission'
                    "
                  >
                    <v-btn
                      @click="changeLoginType('department')"
                      :class="{ active: loginType == 'department' }"
                      >{{ $t("Администратор") }}</v-btn
                    >
                    <v-btn
                      @click="changeLoginType('commission')"
                      :class="{ active: loginType == 'commission' }"
                      >{{ $t("Комиссия") }}
                    </v-btn>
                  </template>

                  <template
                    v-else-if="
                      loginType == 'gos-organization' ||
                      loginType == 'admin2' ||
                      loginType == 'staff2'
                    "
                  >
                    <v-btn
                      @click="changeLoginType('gos-organization')"
                      :class="{ active: loginType == 'gos-organization' }"
                      >{{
                        $t("Художественные школы (Дворец школьников)")
                      }}</v-btn
                    >
                    <v-btn
                      @click="changeLoginType('admin2')"
                      :class="{ active: loginType == 'admin2' }"
                      >{{ $t("Администратор") }}
                    </v-btn>

                    <v-btn
                      @click="changeLoginType('staff2')"
                      :class="{ active: loginType == 'staff2' }"
                      >{{ $t("Сотрудник") }}
                    </v-btn>
                  </template>

                  <template
                    v-else-if="
                      loginType == 'organization' ||
                      loginType == 'staff' ||
                      loginType == 'admin'
                    "
                  >
                    <v-btn
                      @click="changeLoginType('organization')"
                      :class="{ active: loginType == 'organization' }"
                      >{{ $t("Руководитель") }}
                    </v-btn>
                    <v-btn
                      @click="changeLoginType('admin')"
                      :class="{ active: loginType == 'admin' }"
                      >{{ $t("Администратор") }}
                    </v-btn>
                    <v-btn
                      @click="changeLoginType('staff')"
                      :class="{ active: loginType == 'staff' }"
                      >{{ $t("Сотрудник") }}
                    </v-btn>
                  </template>
                </div>
              </v-col>
            </v-row>
            <v-form
              class="login-form"
              ref="form"
              @submit.prevent="handleSubmit"
            >
              <v-file-input
                color="indigo lighten-1"
                class="mb-4"
                :label="$t('Выберите файл')"
                accept=".p12"
                :rules="[(v) => !!v || $t('Выберите ЭЦП')]"
                @change="toBase64"
              ></v-file-input>
              <v-text-field
                color="indigo lighten-1"
                :label="$t('Пароль')"
                prepend-icon="mdi-key"
                v-model="ecpPassword"
                :type="showPassword ? 'text' : 'password'"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[(v) => !!v || $t('Введите пароль')]"
                @click:append="showPassword = !showPassword"
              ></v-text-field>

              <v-flex class="d-flex justify-center">
                <v-btn
                  height="50px"
                  class="login-btn"
                  type="submit"
                  :loading="loading"
                >
                  {{ $t("Войти") }}
                </v-btn>
              </v-flex>
            </v-form>
          </div>
        </v-col>
      </v-row>
      <v-dialog v-model="otherAccountsDialog" max-width="500" persistent>
        <v-card>
          <v-card-title class="text-h5">
            {{ $t("Выберите организацию") }}
            <v-spacer></v-spacer>
            <v-btn icon small @click="closeAccountDialog()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text>
            <v-list three-line>
              <v-list-item
                v-for="(account, accountKey) in otherAccounts"
                :key="accountKey"
                @click="selectAccount(account)"
              >
                <v-list-item-content>
                  <v-list-item-title>{{
                    account.user.branch_name
                  }}</v-list-item-title>
                  <v-list-item-subtitle
                    ><b>{{ account.user.organization_name }}</b>
                    {{ account.user.full_address }}</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
    
  </v-app>
</template>

<script>
export default {
  created() {
    // TODO FIX IT!!!!
    let langIndex = location.hash.search(/\?lang/);
    if (langIndex != -1) {
      let lang = location.hash.substring(langIndex + 6, langIndex + 8);
      location.hash = location.hash.replace("?lang=" + lang, "");
      this.changeLanguage(lang);
    }
  },
  mounted() {
    if (this.$route.params.loginType) {
      this.changeLoginType(this.$route.params.loginType, false);
    } else {
      this.changeLoginType("organization");
    }
  },
  computed: {
    selectedLanguage() {
      return this.$store.state.language;
    },
  },

  data() {
    return {
      showPassword: false,
      ecpFileData: "",
      ecpPassword: "",
      emailData: '', 
      showEmail: false,
      loading: false,
      loginType: "",
      languages: {
        kk: "Қазақша",
        ru: "Русский",
      },
      links: {
        "/": this.$t("Главная"),
      },
      activeTab: null,
      otherAccounts: [],
      otherAccountsDialog: false,
      userInfo: null,
    };
  },
  methods: {
    changeLoginType(type, push = true) {
      if (this.loginType != type) {
        this.$refs.form.reset();
        this.ecpFileData = "";
        this.ecpPassword = "";
        this.loginType = type;
        if (this.loginType == "head-department") {
          this.activeTab = 0;
        } else if (
          this.loginType == "department" ||
          this.loginType == "commission"
        ) {
          this.activeTab = 1;
        } else if (
          this.loginType == "organization" ||
          this.loginType == "staff" ||
          this.loginType == "admin"
        ) {
          this.activeTab = 2;
        } else if (this.loginType == "parent") {
          this.activeTab = 3;
        } else if (
          this.loginType == "gos-organization" ||
          this.loginType == "admin2" ||
          this.loginType == "staff2"
        ) {
          this.activeTab = 4;
        }
        if (push) {
          this.$router.push({ path: "/login/" + type });
        }
      }
    },
    async handleSubmit() {
      if (this.loginType == "staff2") {
        this.loginType = "staff";
      } else if (this.loginType == "admin2") {
        this.loginType = "admin";
      }
      let postData = {
  password: this.ecpPassword
};

if (this.loginType === "superadmin") {
  postData.email = this.emailData;
  console.log("Superadmin login data:", this.emailData);
} else {
  postData.p12 = this.ecpFileData;
  console.log("Other login data:", this.ecpFileData);
}

this.routerlink = this.loginType === "superadmin" ? "/auth" : "/ecp-auth";
console.log("Routerlink:", this.routerlink);

if (this.$refs.form.validate()) {
  this.loading = true;
  try {
    const response = await this.$http.post(this.selectedLanguage + "/" + this.loginType + this.routerlink, postData);
    this.loading = false;

    if (this.loginType === "parent") {
      localStorage.removeItem("parentData");
    }

    if (this.loginType == "admin") {
      if (response.data.other_accounts.length == 1) {
        this.login(response.data.token, response.data.user);
      } else {
        this.userInfo = response.data.user;
        this.otherAccounts = response.data.other_accounts;
        this.otherAccountsDialog = true;
      }
    } else {
      this.login(response.data.token, response.data.user);
    }
  } catch (error) {
    this.loading = false;
    console.error("Error during login:", error);
  }
} else {
  console.error("Form validation failed");
}

// Для отладки, выводим postData в консоль
console.log("PostData:", postData);

    },
    login(token, user, otherAccounts = []) {
      this.$store
        .dispatch("login", {
          loginType: this.loginType,
          token: token,
          user: user,
          otherAccounts: otherAccounts,
        })
        .then(() => {
          // if(this.$router.history.current.query.url && this.$router.history.current.query.url.indexOf('/'+this.loginType) === 0) {
          // 	this.$router.push({ path: this.$router.history.current.query.url }); // TODO
          // } else {
          // 	this.$router.push({ path: '/'+this.loginType });
          // }
          this.$router.push({ path: "/" + this.loginType });
        });
    },

    closeAccountDialog() {
      this.userInfo = null;
      this.otherAccountsDialog = false;
      this.otherAccounts = [];
    },
    selectAccount(account) {
      this.login(account.token, this.userInfo, this.otherAccounts);
      this.otherAccounts = [];
      this.otherAccountsDialog = false;
    },
    changeLanguage(lang) {
      this.$store.commit("setLanguage", lang);
      location.reload();
    },
    toBase64(file) {
      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          let encoded = reader.result.toString().replace(/^data:(.*,)?/, "");
          if (encoded.length % 4 > 0) {
            encoded += "=".repeat(4 - (encoded.length % 4));
          }
          this.ecpFileData = encoded;
        };
      }
    },
  },
  watch: {},
};
</script>
<style scoped>
#app {
  background-color: #f6f6f6;
}
.v-toolbar {
  background-color: #0066ff !important;
}
.login-title {
  font-size: 36px;
}
.v-btn.login-btn {
  background-color: #0066ff;
  box-shadow: 0px 0px 30px rgba(0, 102, 255, 0.25);
  border-radius: 10px;
  color: #fff;
  margin-top: 25px;
  padding: 26px 60px;
  text-transform: initial;
  font-size: 16px;
  font-weight: 400;
}
.form-wrap {
  background-color: #fff;
  border-radius: 6px;
  border: 1px solid #ccc;
}
.login-form-block {
}
.login-btn-group {
  text-align: center;
  margin-bottom: 30px;
}
.login-btn-group button.v-btn {
  border-radius: 10px;
  text-transform: initial;
  background-color: #e2eeff;
  box-shadow: none;
  font-weight: 400;
  letter-spacing: 0px;
  font-size: 15px;
  padding: 22px 35px;
}
.login-btn-group button.v-btn + button.v-btn {
  margin-left: 10px;
}
.login-btn-group button.active {
  background-color: #35445a;
  box-shadow: 0px 0px 30px rgba(0, 102, 255, 0.25);
  color: #fff;
}

.header-menu-item {
  text-transform: initial;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.1px;
}
.header-menu-item.active {
  background-color: rgb(255 255 255 / 20%);
  box-shadow: 0px 0px 10px rgb(14 14 14 / 20%);
  border-radius: 10px;
}
.header-menu-item {
  margin-right: 5px;
}
.container {
  max-width: 1185px;
}
</style>
